@charset "utf-8";
/* CSS Document */

body {
    font-family: Arial, sans-serif;
    color: #333;
}
/* Header Styles */
.header {
    position: relative;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 0;
}
.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ab; /* Overlay color */
}
.header .container {
    position: relative;
    z-index: 1;
}

.header button {
    background-color:#00ab55;
    color: #fff;
    padding: 10px 20px;
    margin-top: 11px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.header button:hover {
    background-color:#007b55;
}
.section button {
    background-color:#00ab55;
    color: #fff;
    padding: 10px 20px;
    margin-top: 11px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.bg-image {
    background-size: cover;
    background-position: center;
    height: 100%; /* Set the height to fill the column */
    padding:250px;
    /*  background-image: url('images/Cazier-judiciar-online-Cluj-2.jpg'); /* Add the background image */
}



.hp-bg-image {
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100%; /* Set the height to fill the column */
    padding:250px; z-index:99;
    /*  background-image: url('images/Cazier-judiciar-online-Cluj-2.jpg'); /* Add the background image */
}
.hp-bg-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ab; /* Overlay color */
}
.hp-bg-image .text {
    position: relative;
    z-index: 1;
}
.text h1 {
    font-size: 32px;
    font-weight: 700;
    color:#FFFFFF;
    margin-bottom: 20px;
}
.text p {
    font-size: 18px;
    color:#FFFFFF;
    margin-bottom: 30px;
}

.accordion {
    max-width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.accordion-item {
    border-bottom: 1px solid #ccc;
}
.accordion-header {
    background-color: #00ab55;
    padding: 15px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    position: relative;
}
.accordion-header::after {
    content: "\002B"; /* Plus sign (+) */
    color:#fff;
    font-weight: bold;
    position: absolute;
    right: 20px;
    transition: transform 0.3s ease;
}

.accordion-header.active::after {
    content: "\2212"; /* Minus sign (-) */
    transform: rotate(90deg);
}
.accordion-content {
    display: none;
    padding: 15px 20px;
    background-color: #fff;
    color: #666;
}
/* Styling for hover effect */
.accordion-header:hover {
    background-color: #007b55;
}

/* Styling for active accordion header */
.accordion-header.active {
    background-color: #e0e0e0;
}
.accordion-content.show {
    display: block; /* Show the content of the active accordion item */
}
.section button:hover {
    background-color:#007b55;
}
.header h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}
.header p {
    font-size: 18px;
    margin-bottom: 30px;
}
/* Section Styles */
.section {
    padding: 60px 20px;
}
.section h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}
.section h2 {
    font-size: 28px;
    margin-bottom: 27px;
}
.section h3 {
    font-size: 23px; color:#333333;
    margin-bottom: 27px;
}
.section p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 23px;
}
.padding-more{

    padding:49px;}
/* Call to Action Styles */
.cta {
    position: relative;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 50px 0;
}
.cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgb(2 2 1 / 76%); /* Overlay color */
}
.cta .container {
    position: relative;
    z-index: 1;
}
.cta h2{
    font-size:28px;
    margin-bottom: 27px;
}
.cta button {
    background-color:#00ab55;
    color: #fff;
    padding: 10px 20px;
    margin-top: 11px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
.cta button:hover {
    background-color:#007b55;
}
.custom-icon {
    margin-right: 5px; /* Add margin between icon and text */
}
ul {
    list-style: none;
    padding-left: 0;
}
ul.checkmarkList>li {
    margin-bottom: 10px;
    padding-left: 30px;
    font-size: 16px;
    line-height: 30px;
    position: relative;
    font-weight: normal;
}
ul.checkmarkList>li:before {
    content: '\f058'; /* Unicode for FontAwesome icon */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #00ab55;
    position: absolute;
    left: 0;
    top: 14px;
    transform: translateY(-50%);
}
/* Media Query for Mobile */
@media (max-width: 768px) {
    .cta button {
        font-size: 14px; /* Decrease font size for mobile */
    }
    .section {
        padding: 50px 20px; /* Adjust padding between rows */
    }
    .cta h2{
        font-size:25px;
    }
    .col-md-6 {
        padding: 10px 0px; /* Add padding between columns */
    }
    .bg-image {
        padding:172px;
        /*  background-image: url('images/Cazier-judiciar-online-Cluj-2.jpg'); /* Add the background image */
    }
}
/* For tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    /* Your CSS rules for tablets here */
    .bg-image {
        padding:244px;
        /*  background-image: url('images/Cazier-judiciar-online-Cluj-2.jpg'); /* Add the background image */
    }
}