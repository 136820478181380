.docs-app-timeline-item::before {
    display: none;
}
.docs-app-timeline-item {
    min-height: 12px;
}

#keepStyles1 {
    font-size: 2rem !important;
    margin: 0 !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    font-family: Public Sans,sans-serif !important;
    padding: 0 !important;
    box-sizing: border-box !important;
}