.container-info-name {
    background-image: url("./../../theme/images/obtinere-cazier-judiciar-online.webp");
    opacity: 0.75;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-weight: bold;
    text-shadow: 10px 10px 25px black,
    -10px 10px 25px darkblue,
    -10px -10px 25px black,
    10px -10px 25px darkblue;
}

#keepStyles2 .MuiTypography-h4 {
    font-family: Public Sans,sans-serif !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    font-size: 1.5rem !important;
    margin: 60px 50px 10px 50px !important;
    padding: 0 !important;
    box-sizing: border-box !important;
}