.Mui-selected {
    background: rgb(0, 171, 85) !important;
    color: white !important;
}

.MuiMenu-paper {
    transition-duration: 0.3s !important;
}

.lwrButton {
    text-transform: lowercase !important;
}

#mouse-over-popover > .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    max-width: 300px;
}

#foreign-checkbox > .MuiCheckbox-root {
    padding: 4px;
}

#foreign-checkbox > .MuiTypography-root {
    padding-top: 2px;
}

.Mui-selected#paymentCard,
.Mui-selected#wireTransfer
{
    background: rgba(0, 271, 85, 0.5) !important;
    color: black !important;
}

.MuiToggleButtonGroup-root#paymentMethods {
    display: flex;
}

.MuiButtonBase-root#paymentCard,
.MuiButtonBase-root#wireTransfer {
    border-radius: 8px;
    border: 1px solid grey;
}

.MuiButtonBase-root#paymentCard {
    margin-right: 8px;
}

.MuiButtonBase-root#wireTransfer {
    margin-left: 8px;
}

.MuiDropzoneArea-root {
    padding: 10px;
    min-height: 80px !important;
}

.MuiDropzoneArea-text{
    margin: 30px 10px 30px 10px !important;
}

.MuiDropzoneArea-textContainer {
    display: flex;
    justify-content: center;
}

.MuiDropzoneArea-icon {
    display: none !important;
}

.MuiDropzonePreviewList-removeButton {
    top: 9px !important;
    right: 14px !important;
    opacity: 1 !important;
}

.MuiGrid-root.MuiDropzonePreviewList-root {
    margin-top: -20px !important;
    justify-content: center !important;
}

.MuiChip-root {
    margin-top: 0px  !important;
}

.regimGroup {
    /*background: red;*/
}

@media (min-width:600px) {
    .phone-number-combined .country-selector,
    .phone-number-combined .phone-number-text-field {
        width: 100% !important;
    }
    .phone-number-combined .country-selector-text-field fieldset {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .phone-number-combined .phone-number-text-field fieldset {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

@media (max-width:599px) {
    .phone-number-combined .phone-number-text-field  {
        margin-top: 20px;
    }

    .MuiToggleButtonGroup-root#paymentMethods {
        display: block;
    }

    .MuiButtonBase-root#paymentCard {
        margin-right: 0px;
    }

    .MuiButtonBase-root#wireTransfer {
        margin-left: 0px;
        margin-top: 10px;
    }
}